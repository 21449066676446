/*global
    FirmCard:false
*/
// FirmCard.dictionary = {};

FirmCard.prototype.dict = {

    t: function (lang, msg, argument) { // (String, Number) -> String
        var result,
            msgIsset = false,
            dictionaryMsg,
            exp;

        if (typeof this[lang] === 'undefined') {
            lang = 'ru';
        }
        dictionaryMsg = this[lang][msg];
        msgIsset = typeof dictionaryMsg !== 'undefined';
        if (!msgIsset) {
            return msg;
        }
        result = msgIsset ? dictionaryMsg : msg;

        if (argument !== undefined) {
            argument = parseInt(argument, 10);
            argument = isNaN(argument) ? 0 : argument;
            exp = this[lang].pluralRules(argument);
            result = argument + ' ' + dictionaryMsg[exp];
        }
        return result ? result : msg;
    },

    ru: {
        pluralRules: function (n) { // (Number)
            if (n % 10 === 1 && n % 100 !== 11) { // 1, 21
                return 0;
            }
            if ((n % 10 >= 2 && n % 10 <= 4 && (n % 10) % 1 === 0) && (n % 100 < 12 || n % 100 > 14)) { // 2, 3
                return 1;
            }

            if ((n % 10 === 0) || (n % 10 >= 5 && n % 10 <= 9 && (n % 10) % 1 === 0) || (n % 100 >= 11 && (n % 100) <= 14 && (n % 100) % 1 === 0)) { // 13, 17
                return 2;
            }
        },

        btnBack: 'Назад',
        btnFindWay: 'Проехать сюда',
        btnEntrance: 'Найти вход',
        linkReviews: ['отзыв', 'отзыва', 'отзывов'],
        linkPhoto: ['фото', 'фото', 'фото'],
        linkBooklet: 'Буклет',
        tommorow: 'завтра',
        afterTommorow: 'послезавтра',
        afterWeek: 'через неделю',
        nextSun: 'в воскресенье',
        nextMon: 'в понедельник',
        nextTue: 'во вторник',
        nextWed: 'в среду',
        nextThu: 'в четверг',
        nextFri: 'в пятницу',
        nextSat: 'в субботу',
        willOpen: 'откроется',
        willClose: 'закроется',
        isOpen: 'Открыто',
        openTill: 'Открыто до ',
        closeIn: 'Закроется через ',
        openAt: 'Откроется в ',
        openIn: 'Откроется через ',
        open: 'Откроется ',
        nHours: ['час', 'часа', 'часов'],
        nMins: ['минуту', 'минуты', 'минут'],
        lunch: 'обед',
        Lunch: 'Обед. ',
        workingDays: 'Рабочие дни',
        weekdays: 'Будние дни',
        restDay: ['выходной', 'выходные','выходные'],
        reviewsOnFlamp: 'Отзывы на Флампе',
        writeReviewOnFlamp: 'Написать отзыв на Флампе',
        payment: 'оплата',
        everyday: 'Ежедневно c',
        worksAroundTheClock: 'Работает круглосуточно',
        aroundTheClock: 'Круглосуточно',
        knowMore: 'узнать больше',
        toClose: 'до закрытия',
        monday: 'понедельник',
        tuesday: 'вторник',
        wednesday: 'среда',
        thursday: 'четверг',
        friday: 'пятница',
        saturday: 'суббота',
        sunday: 'воскресенье',
        mon: 'пон',
        tue: 'втр',
        wed: 'срд',
        thu: 'чтв',
        fri: 'птн',
        sat: 'сбт',
        sun: 'вск',
        toLunch: 'до обеда',
        today: 'Сегодня',
        lessThenHour: 'менее часа',
        youCouldLate: 'вы можете не успеть',
        workingTime: 'рабочее время',
        showAllOrgInRubric: 'Показать все организации рубрики',
        todayIsRestDay: 'Сегодня выходной',
        internet: 'Оплата через Интернет',
        noncash: 'Безналичный расчет',
        goldcrown: 'Золотая Корона',
        dinersclub: 'Diners Club',
        mastercard: 'Mastercard',
        maestrocard: 'MaestroCard',
        visa: 'Visa',
        cash: 'Наличный расчет',
        americanexpress: 'American Express',
        hour : 'час',
        less: 'менее',
        _in : 'Через',
        isClosingOnDinner : ' закрывается на обед'
    },

    it: {
        pluralRules: function (n) { // (Number)
            if (n === 1) { // 1
                return 0;
            } else {
                return 1; //0, 2, 3, 4 ..
            }
        },

        btnBack: 'Indietro',
        btnFindWay: 'Come arrivare ',
        btnEntrance: 'Trova l\'ingresso',
        linkReviews: ['recensione', 'recensioni'],
        linkPhoto: ['fotografia', 'fotografie'],
        linkBooklet: 'Sull\'azienda',
        tommorow: 'domani',
        afterTommorow: 'dopodomani',
        afterWeek: 'tra una settimana',
        nextSun: 'la domenica',
        nextMon: 'il lunedi',
        nextTue: 'il martedì',
        nextWed: 'il mercoledì',
        nextThu: 'il giovedi',
        nextFri: 'il venerdì',
        nextSat: 'il sabato',
        willOpen: 'apre',
        willClose: 'ciuso',
        isOpen: 'Aperto',
        openTill: 'Aperto fino alle ',
        closeIn: 'Chiude tra ',
        openAt: 'Apre alle ',
        openIn: 'Apre tra ',
        open: 'Apre ',
        nHours: ['ora', 'ore'],
        nMins: ['minuto', 'minuti'],
        lunch: 'pausa pranzo',
        Lunch: 'Pausa pranzo. ',
        workingDays: 'Giorni feriali',
        weekdays: 'Giorni feriali',
        restDay: ['chiusura','chiusura'],
        reviewsOnFlamp: 'Recensioni su Flamp',
        writeReviewOnFlamp: 'Scrivi una recensione su Flamp',
        payment: 'pagamento',
        everyday: 'Ogni giorno dalole',
        worksAroundTheClock: 'Operativo 24 ore su 24',
        aroundTheClock: '24 ore su 24',
        knowMore: 'ulteriori informazioni',
        toClose: 'fino alla chiusura',
        monday: 'lunedi',
        tuesday: 'martedì',
        wednesday: 'mercoledì',
        thursday: 'giovedi',
        friday: 'venerdì',
        saturday: 'sabato',
        sunday: 'domenica',
        mon: 'lun',
        tue: 'mar',
        wed: 'mer',
        thu: 'gio',
        fri: 'ven',
        sat: 'sab',
        sun: 'dom',
        toLunch: 'fino alla pausa pranzo',
        today: 'Oggi',
        lessThenHour: 'meno di un\'ora',
        youCouldLate: 'affrettarsi, chiusura imminente',
        workingTime: 'orario di lavoro',
        showAllOrgInRubric: 'Visualizza tutte le aziende della categoria',
        todayIsRestDay: 'oggi chiuso',
        internet: 'Pagamento on-line',
        noncash: 'Pagamento non in contanti',
        goldcrown: 'Zolotaja Korona',
        dinersclub: 'Diners Club',
        mastercard: 'MasterCard',
        maestrocard: 'MaestroCard',
        visa: 'Visa',
        cash: 'Pagamento in contanti',
        americanexpress: 'American Express',
        hour : 'un\'ora',
        less: 'meno di',
        _in : 'Tra',
        isClosingOnDinner : 'chiude per pausa pranzo'
    },

    en: {
        pluralRules: function (n) { // (Number)
            if (n === 1) { // 1
                return 0;
            } else {
                return 1; //0, 2, 3, 4 ..
            }
        },

        btnBack: 'Back',
        btnFindWay: 'Get here',
        btnEntrance: 'Find entrance',
        linkReviews: ['review', 'reviews'],
        linkPhoto: ['photo', 'photos'],
        linkBooklet: 'About company',
        tommorow: 'tomorrow',
        afterTommorow: 'the day after tomorrow',
        afterWeek: 'in a week',
        nextSun: 'on Sunday',
        nextMon: 'on Monday',
        nextTue: 'on Tuesday',
        nextWed: 'on Wednesday',
        nextThu: 'on Thursday',
        nextFri: 'on Friday',
        nextSat: 'on Saturday',
        willOpen: 'opens ',
        willClose: 'closes',
        isOpen: 'Open',
        openTill: 'Open till ',
        closeIn: 'Closes in ',
        openAt: 'Opens at ',
        openIn: 'Opens in ',
        open: 'Opens ',
        nHours: ['hour', 'hours'],
        nMins: ['minute', 'minutes'],
        lunch: 'lunch break',
        Lunch: 'Lunch break. ',
        workingDays: 'Working days',
        weekdays: 'Weekdays',
        restDay: ['day off','days off'],
        reviewsOnFlamp: 'Reviews on Flamp',
        writeReviewOnFlamp: 'Write a review on Flamp',
        payment: 'payment',
        everyday: 'Daily from',
        worksAroundTheClock: 'Open 24 hours',
        aroundTheClock: '24h',
        knowMore: 'see also',
        toClose: 'until closing',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
        sun: 'Sun',
        toLunch: 'until lunch',
        today: 'Today',
        lessThenHour: 'less then one hour',
        youCouldLate: 'you might be late',
        workingTime: 'working hours',
        showAllOrgInRubric: 'Show all organizations in the category',
        todayIsRestDay: 'closed today',
        internet: 'Online',
        noncash: ' No-cash',
        goldcrown: 'Golden Crown',
        dinersclub: 'Diners Club',
        mastercard: 'Mastercard',
        maestrocard: 'MaestroCard',
        visa: 'Visa',
        cash: 'Cash',
        americanexpress: 'American Express',
        hour : 'hour',
        less: 'less',
        _in : 'In',
        isClosingOnDinner : 'will be closing for lunch'
    },

    cs: {
        pluralRules: function (n) { // (Number)
            return (n === 1) ? 0 : (n >= 2 && n <= 4) ? 1 : 2;
        },

        btnBack: 'Zpět',
        btnFindWay: 'Jet sem',
        btnEntrance: 'Hledat vstup',
        linkReviews: ['recenze', 'recenze', 'recenzí'],
        linkPhoto: ['fotografie', 'fotografie', 'fotografií'],
        linkBooklet: 'Leták',
        tommorow: 'zítra',
        afterTommorow: 'pozítří',
        afterWeek: 'za týden',
        nextSun: 'v neděli',
        nextMon: 'v pondělí',
        nextTue: 'v úterý',
        nextWed: 've středu',
        nextThu: 've čtvrtek',
        nextFri: 'v pátek',
        nextSat: 'v sobotu',
        willOpen: 'otevře se',
        willClose: 'zavře se',
        isOpen: 'Otevřeno',
        openTill: 'Otevřeno do ',
        closeIn: 'Zavře se za ',
        openAt: 'Otevře se v ',
        openIn: 'Otevře se za ',
        open: 'Otevře se ',
        nHours: [ 'hodinu' , 'hodiny' , 'hodin' ],
        nMins: [ 'minutu' , 'minuty' , 'minut' ],
        lunch: 'polední přestávka',
        Lunch: 'Polední přestávka. ',
        workingDays: 'Všední dny',
        weekdays: 'Všední dny',
        restDay: ['zavřeno', 'zavřeno'],
        reviewsOnFlamp: 'Recenze na Flampu',
        writeReviewOnFlamp: 'Napsat recenzi na Flampu',
        payment: 'platba',
        everyday: 'Denně od ',
        worksAroundTheClock: 'Pracuje nonstop',
        aroundTheClock: 'Nonstop',
        knowMore: 'dozvědět se více',
        toClose: 'do uzavření',
        monday: 'pondělí',
        tuesday: 'úterý',
        wednesday: 'středa',
        thursday: 'čtvrtek',
        friday: 'pátek',
        saturday: 'sobota',
        sunday: 'neděle',
        mon: 'po',
        tue: 'út',
        wed: 'st',
        thu: 'čt',
        fri: 'pá',
        sat: 'so',
        sun: 'ne',
        toLunch: 'do polední přestávky',
        today: 'Dnes',
        lessThenHour: 'méně než hodinu',
        youCouldLate: 'nemusíte to stihnout',
        workingTime: 'pracovní doba',
        showAllOrgInRubric: 'Zobrazit všechny organizace v rubrice',
        todayIsRestDay: 'Dnes je zavřeno',
        internet: 'Platba prostřednictvím Internetu',
        noncash: 'Bezhotovostní platba',
        goldcrown: 'Zolotaja Korona',
        dinersclub: 'Diners Club',
        mastercard: 'Mastercard',
        maestrocard: 'MaestroCard',
        visa: 'Visa',
        cash: 'Platba hotově',
        americanexpress: 'American Express',
        hour : 'hodinu',
        less: 'méně',
        _in : 'Za',
        isClosingOnDinner : 'začíná polední přestávka'
    },

    es: {
        pluralRules: function (n) { // (Number)
          return (n === 1) ? 0 : (n >= 2 && n <= 4) ? 1 : 2;
        },

        btnBack: 'Atrás',
        btnFindWay: 'Ir para allá',
        btnEntrance: 'Buscar acceso',
        linkReviews: ['comentario', 'comentarios', 'comentarios'],
        linkPhoto: ['foto', 'fotos', 'fotos'],
        linkBooklet: 'Folleto',
        tommorow: 'mañana',
        afterTommorow: 'pasado mañana',
        afterWeek: 'en una semana más',
        nextSun: 'el domingo',
        nextMon: 'el lunes',
        nextTue: 'el martes',
        nextWed: 'el miércoles',
        nextThu: 'el jueves',
        nextFri: 'el viernes',
        nextSat: 'el sábado',
        willOpen: 'se abrirá',
        willClose: 'se cerrará',
        isOpen: 'Abierto',
        openTill: 'Abierto hasta ',
        closeIn: 'Se cerrará dentro de ',
        openAt: 'Se abrirá el ',
        openIn: 'Se abrirá dentro de ',
        open: 'Se abrirá ',
        nHours: ['hora', 'horas', 'horas'],
        nMins: ['minuto', 'minutos', 'minutos'],
        lunch: 'hora de colación',
        Lunch: 'Hora de colación. ',
        workingDays: 'Días laborables',
        weekdays: 'Días laborables',
        restDay: ['cerrado','cerrado'],
        reviewsOnFlamp: 'Comentarios en Flamp',
        writeReviewOnFlamp: 'Escribir un comentario en Flamp',
        payment: 'pago',
        everyday: 'Cada día desde',
        worksAroundTheClock: 'Abierto las 24 horas',
        aroundTheClock: '24 horas',
        knowMore: 'para saber más',
        toClose: 'hasta el cierre',
        monday: 'lunes',
        tuesday: 'martes',
        wednesday: 'miércoles',
        thursday: 'jueves',
        friday: 'viernes',
        saturday: 'sábado',
        sunday: 'domingo',
        mon: 'lun',
        tue: 'mar',
        wed: 'mié',
        thu: 'jue',
        fri: 'vie',
        sat: 'sáb',
        sun: 'dom',
        toLunch: 'antes de la hora de colación',
        today: 'Hoy',
        lessThenHour: 'menos de una hora',
        youCouldLate: 'puede ser que no alcanzas a llegar',
        workingTime: 'horario de trabajo',
        showAllOrgInRubric: 'Mostrar todas las empresas de la categoría',
        todayIsRestDay: 'Hoy cerrado',
        internet: 'Pago por Internet',
        noncash: 'Pago sin efectivo',
        goldcrown: 'Zolotaya Korona',
        dinersclub: 'Diners Club',
        mastercard: 'Mastercard',
        maestrocard: 'MaestroCard',
        visa: 'Visa',
        cash: 'Pago en efectivo',
        americanexpress: 'American Express',
        hour : 'hora',
        less: 'menos de',
        _in : 'Dentro de',
        isClosingOnDinner : 'se cierra por hora de colación'
    }
};
