/*
 * Predefined arrow tips
 */

/* eslint-disable array-bracket-spacing */
DG.Entrance.Arrow.SHAPE = {
    vertices: {
        16: [
            [ -6.5000,  -1.8000],
            [ -6.0522,  -1.8000],
            [ -7.0975,  -5.2537],
            [ -6.6619,  -6.2565], [ -6.5980,  -6.3550], [ -6.1757,  -6.1470],
            [  0.8371,  -0.3552],
            [  0.9275,  -0.1764], [  0.9275,   0.1764], [  0.8371,   0.3552],
            [ -6.1757,   6.1470],
            [ -6.5980,   6.3550], [ -6.6619,   6.2565], [ -7.0975,   5.2537],
            [ -6.0522,   1.8000],
            [ -6.5000,   1.8000]
        ],
        17: [
            [ -9.0000,  -2.4000],
            [ -7.8890,  -2.4000],
            [ -9.9245,  -7.2548],
            [ -9.3363,  -8.6404], [ -9.3448,  -8.6448], [ -8.8717,  -8.3508],
            [  1.0285,  -0.3552],
            [  1.1190,  -0.1764], [  1.1190,   0.1764], [  1.0285,   0.3552],
            [ -8.8717,   8.3508],
            [ -9.3448,   8.6448], [ -9.3363,   8.6404], [ -9.9245,   7.2548],
            [ -7.8890,   2.4000],
            [ -9.0000,   2.4000]
        ],
        18: [
            [-11.5000,  -3.0000],
            [-10.0795,  -3.0000],
            [-12.4909,  -9.3173],
            [-11.8402, -10.7654], [-11.5986, -10.7073], [-10.9380, -10.2258],
            [  1.1497,  -0.3552],
            [  1.2402,  -0.1764], [  1.2402,   0.1764], [  1.1497,   0.3552],
            [-10.9380,  10.2258],
            [-11.5986,  10.7073], [-11.8402,  10.7654], [-12.4909,   9.3173],
            [-10.0795,   3.0000],
            [-11.5000,   3.0000]
        ],
        19: [
            [-13.0000,  -3.6000],
            [-11.6600,  -3.6000],
            [-14.1696, -10.8351],
            [-13.5189, -12.2832], [-13.2773, -12.2251], [-12.6167, -11.7436],
            [  1.3061,  -0.3552],
            [  1.3966,  -0.1764], [  1.3966,   0.1764], [  1.3061,   0.3552],
            [-12.6167,  11.7436],
            [-13.2773,  12.2251], [-13.5189,  12.2832], [-14.1696,  10.8351],
            [-11.6600,   3.6000],
            [-13.0000,   3.6000]
        ]
    },
    drawings: {
        16: ['M', 'L', 'L', 'C', 'L', 'C', 'L', 'C', 'L', 'L'],
        17: ['M', 'L', 'L', 'C', 'L', 'C', 'L', 'C', 'L', 'L'],
        18: ['M', 'L', 'L', 'C', 'L', 'C', 'L', 'C', 'L', 'L'],
        19: ['M', 'L', 'L', 'C', 'L', 'C', 'L', 'C', 'L', 'L']
    }
};
/* eslint-enable array-bracket-spacing */


(function(vertices) {
    for (var i in vertices) {
        vertices[i] = vertices[i].map(function(vertex) { return DG.point(vertex); });
    }
})(DG.Entrance.Arrow.SHAPE.vertices);
